html * {
  font-family: 'Open Sans', sans-serif;
}

body {
  height: 100vh;
  background-color: white;
}

#root {
  height: 100%;
}

.rdt_TableHeader {
  background: transparent;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.rdt_Table {
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))!important;
  border-width: 1px !important;
}

.rdt_TableHeadRow {
  color: #6B7280 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  background-color: #F9FAFB !important;
  border-width: 0 0 1px 0 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}

.rdt_TableCol {
  border-right: 0 !important;
}

.rdt_TableCol input[type='checkbox'], .rdt_TableCell input[type='checkbox'] { 
  border-radius: 3px !important;
  border-color: #D1D5DB !important;
}

.rdt_TableCell {
  border-right: 0 !important;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  overflow: hidden !important;
  font-size: 15px !important;
  color: #6B7280 !important;
}

.rdt_TableCell div:first-childd {
  overflow: unset !important;
  white-space: initial !important;
}

.rdt_Pagination {
  border-top: 0 !important;
}

.rdt_Pagination div > svg {
  display: none !important;
}

/* #text-editor {
  width: 900px;
  margin-left: 20%;
  margin-top: 50px;
} */